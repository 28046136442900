
.flyers {
    margin: 0 auto;
    // width: 820px;
    font-size: 2.5em;
    text-align: center;
    max-width: 100%;
    height: 800px;

    a  {
        text-decoration: none;
    }
    .carousel-item {
        // padding: 10rem;
        // background-repeat: no-repeat;
        // background-size: 100%;
        // background-position: 0 -18rem;
        height: 100%;
        max-height: 800px;

        .content {
            background: rgba(0,0,0,1);
            // width: 400px;
            // margin: 0 auto;    
            // margin-top: 12rem;

            .inner-content {
                // padding: 1rem;
            }

            img {
                height: 100%;
                max-height: 800px;
                width:auto;
                        // width:min-content;
                //  width: 600px;
                // height:100%;
            }
        }
    }

    // @media screen and (max-width: 1320px) {
    //     .carousel-item {
    //         height: 500px;
    //         .content {
    //             margin-top: 5rem;
    //             width: 800px;
    //         }
    //     }
    // }  
      
    @media screen and (max-width: 1100px) {
        display: none;
    }  
}
