#photos {
    img {
        &.h {
            max-width: 300px;
            height: fit-content;
        }
        &.v {
            max-height: 300px;
            width: fit-content;
        }

        margin: 1rem;
        border: ridge 2px red;
    }
}