#animations {
    img {
        &.h {
            max-width: 200px;
            height: fit-content;
        }
        &.v {
            max-height: 200px;
            width: fit-content;
        }
        margin: .1rem;
        border: ridge 2px red;
    }
    // .section {
    //     text-decoration: underline;
    //     font-weight: 600;
    // }

    .animation {
        // border: solid 1px black;
    background-color: rgba(255,210,10,.4);
        // background: rgba(155,0,0,0.7);
        margin: 2rem;
        padding: 1rem;
        font-size: 1.2em;

        h4 {
            // color: rgba(255,210,10,0.9);
            color: rgba(118,27,123,0.9);
            font-weight: 800;
            font-size: 1.2em;
            margin: 0;
        }

        p {
            // color: white;
            // color: rgba(258,187,253,0.9);
        }

        .photos {
            display: flex;
            flex-wrap: wrap;
            margin: 1rem;

            img {
                margin: 1rem;
                max-height: 300px;
                max-width: 300px;
            }
        }
    }
}