
#home {
/* Eléments principaux de la page */
  margin-top: 5rem;

  h3  {
    color: #8e44ad;
  }

  h4 {
    color: #d3190b;
  }

  & footer h1, #tiles a
  {
      font-weight: normal;
  }

  #image-crea {
    text-align: center;
  }
  #image-crea img {
    width: 700px;
    max-width: 80%;
  }

  #main-content {
    width: calc(100% - 400px);
    min-width: 520px;
    margin: 0 auto;
  }

  #right-panel {
    max-width: 25rem;
    margin: 0 auto;
  }

  input[type=button], .button {
    width: 300px;
  }

  .pane {
    background-color: rgba(255,210,10,.4);
    color: rgba(118,27,123,0.9);
    padding: 1rem;
    margin: 1rem;
  }

  .color-black {
    color: black;
  }

  .highlight {
    background-color: rgba(255,210,10,0.9);// #fdaa00aa;
    border: 15px rgba(118,27,123,0.9) ridge;
  }

  #button-inscription {
    a {
      color: rgba(255,210,10,0.9);
      background-color: rgba(118,27,123,0.9);
    }
    text-align: center;
  }
    
  & ul#tiles
  {
    display: flex;
    flex-wrap: wrap;
    margin: 3rem;
    padding: 0rem;
    list-style-type: none;

    & a
    {
      font-size: 1.3em;
      color: #FFFFAA;
      padding-bottom: 3px;
      margin: 0;
      border: 3px solid #000024;

      &:hover, &:active
      {
          color: white;
          // border-bottom: 3px solid white;
          border: 3px ridge rgba(200,200,255,.2);
      }

      width: 200px;
      height: 200px;
      margin-right: .8rem;
      margin-bottom: 1rem;
      overflow: hidden;
      background: rgba(200,200,255,.1);

      &.full
      {
        width: 418px;
        height: 200px;
        // background: url('http://blog.usidistrib.be/images/empty_large_tile.png') no-repeat;
      }
    }
              
    & .pic
    {
      margin-left: 25px;
      margin-top: 8px;
      height: 150px;
      width: 150px;
    }
      
    & .pic_full
    {
      height: 8.5rem;
      margin: 0 auto;
      margin-top: 1rem;
      width: 8.5rem;

      & img {
        width: 100%;
        height: 100%;
      }
    }

    & .pic_full#apps {
      display: flex;
      flex-wrap: wrap;

      & img {
        width: 50%;
        height: 50%;
      }
    } 

    & .pic_large_full
    {
      height: 8.5rem;
      width: 411px;
    }

    & .pic_large_full img
    {
      margin-left: 261px;
    }

    & .pic_180
    {
      margin: 0 auto;
      margin-top: 1rem;
      height: 8.5rem;
      width: 8.5rem;
    }

    & img
    {
      border: none;
    }

    & .pic img
    {
      max-width: 150px;
    }

    & .tile_title
    {
      display: block;
      margin-left: 20px;
      padding-top: 10px;
    }

  }


  & #apps
  {
    margin: 0;
  }

  & #apps img
  {
    /* margin-right: -5.5px; */
    margin-bottom: -5.3px;
  }

  & #cal
  {
    margin-top: 1rem;
    margin-left: 1rem;
    height: 8.5rem;
    display: flex;
    // background: url('http://blog.usidistrib.be/images/empty_large_tile.png') no-repeat;

    & #cal-left {
      flex: 2 1;
      // background: red;
    }

    & #cal-right {
      flex: 1 1;
      // background: green;
    }
  }

  & #day_num
  {
    font-size: 4em;
    // background: blue;
    margin: -1.2rem 0;
    margin-left: .1rem;
    // position: relative;
  }

  & #day_text
  {
    // background: blue;
    margin: .4rem .8rem;
  }

  & .cal_small
  {
    margin: 0px;
    font-size: .8em;
  }

  /* Footer */

  & footer
  {
      // background:  url('http://blog.usidistrib.be/images/zozor/ico_top.png') no-repeat top center, url('http://blog.usidistrib.be/images/zozor/separateur.png') repeat-x top, url('http://blog.usidistrib.be/images/zozor/ombre.png') repeat-x top;
      padding-top: 25px;
  }

  & footer p, footer ul
  {
      font-size: 0.8em;
  }

  & footer h1
  {
      font-size: 1.1em;
  }

  & #tweet, #mes_photos, #mes_amis
  {
      display: inline-block;
      vertical-align: top;
  }

  & #tweet
  {
      width: 28%;
  }

  & #mes_photos
  {
      width: 35%;
  }

  & #mes_amis
  {
      width: 31%;
  }

  & #mes_photos img
  {
      border: 1px solid #181818;
      margin-right: 2px;
  }

  & #mes_amis ul
  {
      display: inline-block;
      vertical-align: top;
      margin-top: 0;
      width: 48%;
      // list-style-image: url('http://blog.usidistrib.be/images/zozor/ico_liensexterne.png');
      padding-left: 2px;
  }

  & #mes_amis a
  {
      text-decoration: none;
      color: #760001;
  }


  & #footnote
  {
    position: fixed;
    bottom: 1px;
    color: white;
    font-size: .6em;
  }

  @media screen and (max-width: 900px) {
    #main-content {
      min-width: 400px;
    }
  }

  @media screen and (max-width: 547px) {
    #main-content {
      min-width: 300px;
    }

    font-size: .7em;
    margin-bottom: 5rem;

    #content {
      padding: .5rem;
    }
  
    & ul#tiles {
      margin: 1rem;
  
      & a {
        width: 150px;
        height: 150px;
  
        &.full {
          max-width: 320px;
          height: 150px;
        }
  
        & #cal {
          height: 6.3rem;
        }
  
        & .pic_180 {
          width: 6.3rem;
          height: 6.3rem;
          & img {
            width: 100%;
          }
        } 
      }
  
      & .pic_full {
        width: 6.3rem;
        height: 6.3rem;
      }
    }
  }
}
