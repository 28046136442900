
.profil-parent {
    background: #FFFFFF44;
    padding: 1em;
}

.profil-children {
    display: flex;
}

.profil-child {
    background: #FFFFFF44;
    width: 200px;
    margin: 1em;
    padding: .2em 2em;
}