#documents {
    margin: 2rem;
    color: white;
    ul {
        display: flex;
        list-style-type: none;
        margin: 0 auto;
        padding: 0;
        justify-content: space-between;
        max-width: 100%;
        flex-wrap: wrap;
    
        a {
            min-width: 100px;
            text-decoration: none;
            margin: 1rem;
            background-color: red;
            padding: 1rem;
            display: block;
            padding-top: 70px;
            text-align: center;
            background: no-repeat url("/images/adobe-pdf-icon.png");
            background-size: 37px 51px;
            background-position-x: center;
            background-position-y: 10px;
            background-color: #FF000077;
            width: 150px;
            border-radius: 2rem;

            &:hover {
                background-color: #FF000099;
            }
        }
    }

    @media screen and (max-width: 900px) {
        ul {
            margin: 0.1rem;
            padding: 0.1rem;
        }
      }
}


