
nav {
    background: rgba(63,40,96,.2);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    & .navbar {
        display: flex;
        justify-content: space-between;
    
        & a {
            text-decoration: none;
        }
    
        & .icon-bar {
            background-color: white;
            &+.icon-bar {
                margin-top: 4px;
            }
        }

        & .navbar-toggle {
            position: relative;
            float: right;
            // padding: 9px 10px;
            margin-top: 3px;
            margin-right: 15px;
            margin-bottom: 8px;
            background-color: transparent;
            background-image: none;
            border: 1px solid transparent;
            border-radius: 4px;
            display: none;
            cursor: pointer;

            & .icon-bar {
                display: block;
                width: 22px;
                height: 2px;
                border-radius: 1px;
            }
        }
    }
    
    & .navbar .navbar-header {
        // background: blue;
        padding: .1rem 1rem;

        .brand {
            font-size: 1.3em;
        }
        .brand_subtitle {
            min-width: 100px;
            color: #fdd00a;
            font-size: .8em;
            font-weight: 100;
            text-transform: uppercase;
            position: relative;
            top: 10px;
            margin-left: 20px;
        }

        & a {
            // display: flex;
            margin: 0;
            padding: 0;
            color: white;
            font-size: 1em;
            font-weight: bold;
            line-height: 1px;
            & img {
                position: relative;
                top: 2px;
                margin-right: 5px;
            }
        }
    }
    
    & .navbar ul {
        // flex: 1 1 auto;
        // background: green;
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        padding: 1rem;
    
        & li {
            margin: 0rem 1rem;
            & a {
                color: white;
                font-weight: bold;
                &:hover, :active, &.selected {
                    color: #FFFFAA;
                }    
            }
        }
    }

    @media screen and (max-width: 1187px) {
        & .navbar .navbar-header {
            max-width: 75%;
              .flex {
                    flex-wrap: wrap;
                }

                .brand {
                    font-size: 0.8em;
                    margin-right: 1rem;
                }
            
                .brand_subtitle {
                    font-size: 0.7em;
                    margin-left: 0;
                    top: 0;
                }
        }
    }

    @media screen and (max-width: 747px) {
        & .navbar {
            display: flex;
            flex-wrap: wrap;
            // background: blue;
            & ul {
                // background: green;
                flex-wrap: wrap;
                & li {
                    width: 200px;
                    padding: .5rem;
                    font-size: 1.2em;
                }
            }

            & .hidden {
                display: none;
            }

            & .navbar-toggle {
                display: block;
            }
        }
    }

    
    @media screen and (max-width: 400px) {
        & .navbar .navbar-header {
            max-width: 75%;
              .flex {
                    flex-wrap: wrap;
                }

                .brand {
                    font-size: 0.8em;
                }
            
                .brand_subtitle {
                    font-size: 0.7em;
                    top: 0;
                }
        }
    }


    @media (horizontal-viewport-segments: 2) 
    {
        width: 100%;

        & .navbar {
            display: flex;
            flex-wrap: wrap;
            // background: blue;
            & ul {
                // background: green;
                flex-wrap: wrap;
                & li {
                    width: 200px;
                    padding: .5rem;
                    font-size: 1.2em;
                }
            }

            & .hidden {
                position: absolute;
                top: 8rem;
                left: 52%;
                width: 80%;
                font-size: 1.8em;

                li {
                    width: 100%;
                }
            }

            & .navbar-toggle {
                display: none;
            }
        }

    }
}


