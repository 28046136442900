#location {
    img {
        &.h {
            max-width: 300px;
            height: fit-content;
        }
        &.v {
            max-height: 300px;
            width: fit-content;
        }
        &.h-large {
            max-width: 100%;
        }
        margin: .4rem;
        border: ridge 2px red;
    }

}