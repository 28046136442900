/* Footer */
footer
{
    /*
    background:  url('images/ico_top.png') no-repeat top center, url('images/separateur.png') repeat-x top, url('images/ombre.png') repeat-x top;
    background-color: rgba(200,200,210,0.5);
    */
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(50,50,115,.6);
    color: white;
    font-size: .8em;

& hr {
  border: solid 1px rgba(255,255,255,.6);
  margin-block-start: 1px;
}

  & .footer-container
  {
      width: 100%;
      flex-wrap: wrap;
      display: flex;
  }

  & .footer-container > p
  {
      min-width: 250px;
      margin: 0px 5px 5px 5px;
  }

  & #footer-container-left
  {
      flex: 1;
      order: 1;
  }

  & #footer-container-right
  {
      flex: 1;
      order: 2;
      text-align: right;
  }

  @media screen and (max-width: 547px) {

    background-color: rgba(50,50,85,.8);
    & #footer-container-left, & #footer-container-right {
        text-align: center;
    }
    
  }    

  @media (horizontal-viewport-segments: 2) 
  {
    width: 48.5%;
  }
}
