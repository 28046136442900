#stage-detail {
    .flex {
        display: flex;
        flex-wrap: wrap;
    }
    img {
        max-width: 400px;
        max-height: 600px;
        &.big {
            max-width: 100%;
        }
    }

    @media screen and (max-width:500px) {
        padding: 0;
        margin: 0;
        img {
            max-width: 350px;
            max-height: 450px;
        }
    }

    @media screen and (max-width:400px) {
        padding: 0;
        margin: 0;
        img {
            max-width: 300px;
            max-height: 400px;
        }
    }
}
@media screen and (max-width:500px) {

    #stages #content {
        margin: 1rem;
        padding: 1rem;
    }

    #stages {
        margin-bottom: 3rem;
    }
}

@media screen and (max-width:400px) {

    #stages #content {
        margin: .5rem;
        padding: .5rem;
    }

    #stages {
        margin-bottom: 4rem;
    }
}
