
.contact-subscribers {
    color: black;
    font-size: 1.3em;

    .error {
        color: red;
    }

    ul {
        li {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }    
        padding: 0;
        margin: 0;
}
}


.contact-form {

    // background-color: white;
    // padding-bottom: 2rem;
    h2 {
        text-align: left;
        margin: 1rem 0rem;
        margin-top: 0;
        font-size: 2em;
        font-weight: 300;
    }

    p {
        color: #666;
    }

    input, textarea {
        border: solid 1px #999;
    }

    input[type=text], textarea, select, option {
        display: block;
        font-size: 1.3em;
        padding: .6rem;
        font-family: Arial;
    }

    input[type=text], textarea {
        font-size: 1.0em;
        width: 500px;
    }

    input[type=text] {
        margin-left: 1rem;
    }

    textarea {
        font-size: 1.0em;
        width: 800px;
        height: 200px;
    }

    input[type=button], .button {
        background: green;
        color: white;
        padding: 1rem;
        border-radius: 10px;
        margin: 1rem;
        width: 200px;
        font-size: 1.0em;
        cursor: pointer;
    }

    label {
        margin: 1rem 0rem;
    }

    .label {
        margin: 0rem;
        margin-top: .5rem;
    }

    &__mail {
        border: solid 1px black;
        padding: 2rem;
        margin: 1rem 0rem;

        & .flex {
            margin: 1rem 0rem;
        }
    }

    &-name {
        p {
            margin: 0;
        }    
    }

    &-email, &-from, &-to, &-comment { //, &-nb-persons
        input, textarea {
            width: 100%;
            max-width: 500px;
        }
    }

    &-email p, &-address p {
        margin-right: 1rem;
        color: black;
    }

    &-address p {
        width: 100px;
    }

    &-submit {
        width: 100%;
        text-align: center;
    }

    .mandatory {
        color: red;
        margin-left: 4px;
    }


    .registration-form-item {
        margin: 1rem 0rem;
    }

    .flex-wrap {
        flex-wrap: wrap ;
    }

    .flex {
        .registration-form-name__first-name {
            margin-right: 1rem;
            p {
                font-size: .8em;
            }
        }
        .registration-form-name__last-name {
            margin-left: 1rem;
            p {
                font-size: .8em;
            }
        }

        &.space-between {
            justify-content: space-between;
        }

        // &.addresses {
        //     & div {
        //         width: 45%;
        //     }
        // }

    }

    @media screen and (max-width: 700px) {
        .flex {
            flex-wrap: wrap;

            .registration-form-name__first-name {
                margin: 0;
                width: 100%;                
            }

            .registration-form-name__last-name {
                margin: 0;
                margin-top: 1rem;
                width: 100%;                
            }

            &.addresses {
                & div {
                    width: 100%;
                }
            }

            &.numbers {
                & div {
                    width: 100%;
                }
            }
        }
        
        input, textarea {
            width: 90%;
        }
}
}