@import url('https://fonts.googleapis.com/css2?family=Averia+Libre:wght@400;700&display=swap');
#infos {
    padding-top: 3rem;
}

#philo {
    img {
        width: 200px;
        margin: 0 auto;
    }
}

#team {
    h4 {
        color: #5A7A75;
        font-family: 'Averia Libre';
        font-size: 30px;
        margin: 1rem 0rem;
    }
    .team-perso {
        background: rgba(255,255,255,0.2);
        padding: 1rem;
        margin: 1rem 0rem;
        img {
            max-width: 200px;
            height: fit-content;
            margin-right: 3rem;
        }
    }
}

.menu-tabs {
    max-width: 500px;
    margin: 0 auto;
    color: red;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;    
}

.menu-tabs a {
    background-color: rgba(200,200,200,.4);
    margin: .2rem;
    padding: 1rem;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.menu-tabs a:hover {
    background-color: rgba(255,210,10,0.5);
    color: rgba(118,27,123,0.9);
}
.menu-tabs a:active,.menu-tabs a.active {
    background-color: rgba(255,210,10,0.9);            
    color: rgba(118,27,123,0.9);
}

.tabs > div {
    display: none;
}

.tabs > div.active {
    display: block;
}



@media screen and (max-width: 900px) {
    #team .flex {
      flex-wrap: wrap;
    }

    #team .flex img {
        max-height: 200px;
    }
  }
