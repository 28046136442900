
.bleu-vert {
    background-color: #008080;
    color: white;
}

.mauve {
    background-color: #800080;
    color: white;
}

.pink {
    background-color: #cc00ff;
    color: white;
}

.green {
    background-color: #ccff66;
}

.dark-pink {
    background-color: #cc0066;
    color: white;
}

.yellow {
    background-color: #ffff00;
}

table#fiches, table#full {
    background-color: white;
    margin: 1rem;
    font-size: .6em;
    border-collapse: collapse;

    td,th {
        min-width: 40px;
        text-align: left;
    }

    td {
        width: 60px;
    }

    th {
        border-left: solid 3px black;
        border-right: solid 3px black;
        border-top: solid 3px black;
        border-bottom: solid 3px black;
    }
    td {

        border: solid 1px black;                
    }

    thead {
        border: solid 3px black;
        font-weight: bold;
        // background-color: red;
    }
    tbody {
        border: solid 3px black;
        th {
            font-weight: normal;
            border-top: solid 1px black;
            border-bottom: solid 1px black;
            }
        // background-color: blue;
    }

    td.align-right {
        text-align: right;
    }
    
}

