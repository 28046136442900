

#courses {
  .menu-tabs {
    max-width: 700px;
  }
}

#horaires {
  h3 {
    color: rgba(175,140,0,0.9);
  }
}

.underline {
  text-decoration: underline;
}

/* GENERAL RULES */
.flex {
  display: flex;
}

.warning {
  color:#A52A2A;

  a {
    color: #EF6D3B;
  }
}

.flex-wrap {
    flex-wrap: wrap ;
}

.flex-space-between {
 justify-content: space-between; 
}

a {
  text-decoration: none;
}

body {
  color: black;
  background-image: url('/images/fond.jpg');
  background-size: 1200px;
  background-repeat: no-repeat;
  background-position-x: calc(50%);
  background-position-y: 0px;
  background-color: #920129;//#a2044c;
}

input[type=button], .button {
  background: green;
  color: white;
  padding: .8rem;
  border-radius: 10px;
  margin: 1rem;
  width: 200px;
  font-size: 1.3em;
  cursor: pointer;
}

a {
  color: white;
}

// #app {
//   margin-bottom: 6rem;
// }

#page {
  margin: 10rem 0 0 0;
}

h1 {
  text-align: left;
  margin-left: 20%;
  font-size:2.5em;
  color:white;
}


#title {
  display: block;
  text-align: center;
  padding-top: .5 rem;
}

#content {
  padding: 2rem;
  margin: 5rem 10%;
  background-color: rgba(255,255,255,.6);

  & #titles {
    font-weight: bold;
    text-align: center;
    font-size: 2.2em;

    & span {
      display: inline-block;
      margin: 0rem 3rem;
      width: 110px;
      text-align: center;
      padding: 2.2rem 0rem;
      border-radius: 4rem;
      font-weight: bold;
    }

    & .head {
      font-weight: bold;
      text-align: left;
      font-size: .6em;
    }

    & span.head {
      padding: 0;
      text-align: center;
    }
    & span.title.head {
      color: transparent;
    }
  }

  & #facteurs {
    text-align: center;
    font-size: 2em;

    & span, & div.head {
      display: inline-block;
      margin: .5rem 3rem;
      text-align: center;
      border-radius: 4rem;
      font-weight: bold;
    }

    & span {
      padding: 2.2rem 0rem;
      width: 110px;
    }

    & div.head {
      text-align: left;
      width: 130px;

      & .head__value {
        font-size: .8em;
      }
    }

    & .head {
      font-weight: bold;
      text-align: left;
      font-size: .6em;
    }

    & .position, & .speed, & .acceleration {
      color: transparent;  
    }
  
  }

  & .home__note {
    width: 100%;
    text-align: center;

    & p {
      margin: 0 auto;
      max-width: 800px;
      font-size: .8em;
    }

    & a {
      text-decoration: none;
      color: #aaa;
      font-weight: bold;
    }

  }

  @media screen and (max-width: 1300px) {
    margin: 2rem 2%;
}

  @media screen and (max-width: 900px) {
    margin: 2rem 1%;

    & #content {

      & #titles {
        width: 100%;
        font-size: .7em;
        & span {
          width: 80px;
          padding: 1.5rem 0rem;
          margin: 0.5rem 1.5rem;
        }
  
        & .head {
          font-size: 1.3em;
          display: inline-block;
          margin: 1.5rem;  
      }  
      }

      & #facteurs {
        font-size: .7em;

        & span {
          width: 80px;
          padding: 1.5rem 0rem;
          margin: 0.5rem 1.5rem;
        }
  
        & .head {
          font-size: 1.3em;
        }  
      }

    }
  }

  @media (horizontal-viewport-segments: 2) 
  {
     width: 50%;
     
    & #content {
      & #titles {
        width: 100%;
        font-size: .7em;
        & span {
          width: 80px;
          padding: 1.5rem 0rem;
          margin: 0.5rem 1.5rem;
        }
  
        & .head {
          font-size: 1.3em;
          display: inline-block;
          margin: 1.5rem;  
      }  
      }

      & #facteurs {
        font-size: .7em;

        & span {
          width: 80px;
          padding: 1.5rem 0rem;
          margin: 0.5rem 1.5rem;
        }
  
        & .head {
          font-size: 1.3em;
        }  
      }

    }
  & #content {
    & #titles {
      margin: 0;
      & .head {
        display: inline-block;
        margin: 1rem 1rem;
        font-size: 1.3em;
      }  
      & span {
        &.title {
          display: none;
        }
      }

    }

    & #facteurs {
      margin: 0;
      font-size: .7em;
      
      & span {
        width: 80px;
        padding: 1.5rem 0rem;
        margin: 0.5rem .8rem;
      }

      & div.head {
        display: flex;
        font-size: 1.3em;
        width: 20rem;
        margin: .5rem 2rem;

        & p {
          display: block;
          margin: 0rem 1rem;
        }
      }  
    }

  }
}

  @media screen and (max-width: 623px)  
  {

    & #content {
      & #titles {
        margin: 0;
        & .head {
          display: inline-block;
          margin: 1rem 1rem;
          font-size: 1.3em;
        }  
        & span {
          &.title {
            display: none;
          }
        }

      }

      & #facteurs {
        margin: 0;
        font-size: .7em;
        
        & span {
          width: 80px;
          padding: 1.5rem 0rem;
          margin: 0.5rem .8rem;
        }
  
        & div.head {
          display: flex;
          font-size: 1.3em;
          width: 20rem;
          margin: .5rem 2rem;
  
          & p {
            display: block;
            margin: 0rem 1rem;
          }
        }  
      }

    }
  }
}


