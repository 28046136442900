#course-list {
    color: rgb(100,50,155);
    background-color: rgba(220,220,150, .3);
    padding: 0rem 2rem;
    padding-bottom: 2rem;
    margin: 0rem 5rem;
    h2 {
        text-align: left;
        margin: 2rem 0rem;
        font-size: 2em;
        font-weight: 300;
    }

    p.course {
        color: black;
        font-size: 1.2em;
    }

    input, textarea {
        border: solid 1px #999;
    }

    input[type=text], textarea, select, option {
        display: block;
        font-size: 1.3em;
        padding: .6rem;
        font-family: Arial;
    }

    .label {
        margin: 0;
        margin-top: .5rem;
    }

    &-name {
        p {
            margin: 0;
        }    
    }

    &-email, &-from, &-to, &-comment { //, &-nb-persons
        input, textarea {
            width: 100%;
            max-width: 500px;
        }
    }

    &-email p, &-address p {
        margin-right: 1rem;
        color: black;
    }

    &-address p {
        width: 100px;
    }

    &-submit {
        width: 100%;
        text-align: center;
    }

    .mandatory {
        color: red;
        margin-left: 4px;
    }

    @media screen and (max-width: 700px) {
        margin: 0;
        font-size: 0.8em;
    }
}